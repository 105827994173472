import styled, { css } from 'styled-components'
import Dropdown from 'react-dropdown'
import Button from '../Button'
import Arrow from '../../layout/icons/arrow-right.svg'
import { breakpoint, ContentAlign, ParagraphStyles } from '../../layout/mixins'
import 'react-dropdown/style.css'


const ArrowCSS = css`
  width: 18px;
  height: 15px;
  fill: ${({ theme }) => theme.colorAccent};
`

export const ArrowDown = styled(Arrow)`
  ${ArrowCSS}
  transform: rotate(90deg)
`

export const ArrowUp = styled(Arrow)`
  ${ArrowCSS}
  transform: rotate(-90deg)
`

export const Content = styled(ContentAlign)`
  display: grid;
  
  row-gap: ${({ theme }) => theme.gutter};
  column-gap: ${({ theme }) => theme.gutter};
  padding-top: ${({ theme }) => theme.gutter};

  ${breakpoint.up('phone')`
    grid-template-columns: [input] minmax(auto, 345px) [button] minmax(auto, 150px);
  `}
`

const InputCSS = css`
  background-color: ${({ theme }) => theme.backgroundColorAlt};
  border: 2px solid #EAF0FA;
  border-radius: ${({ theme }) => theme.borderRadius};
  color: ${({ theme }) => theme.colorAccent};
  padding: ${({ theme }) => theme.gutterInput};
  line-height:normal !important;
  font-size: 1rem;

  &::placeholder {
    color: ${({ theme }) => theme.colorAccent};
    opacity: 1;
  }
`

export const StyledDropdown = styled(Dropdown)`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  width: 100%;
  max-width: 345px;
  grid-column: input / input;
  cursor: pointer;

  .Dropdown-control {
    ${InputCSS}
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    cursor: default;
    outline: none;
    display: flex;
    justify-content: space-between;
    transition: none;
  }

  ${({ value, theme }) => value && `
    &:not(.is-open)  .Dropdown-control {
      border-color: ${theme.colorSuccess};
      color: ${theme.colorSuccess};
      ${ArrowDown} {
        fill: ${theme.colorSuccess};
      }
    }
  `}

  &.is-open .Dropdown-control {
    border-color: ${({ theme }) => theme.colorAccent};
  }

  .Dropdown-menu {
    border: 2px solid ${({ theme }) => theme.colorAccent};
    border-top: 0;
    border-bottom-left-radius: ${({ theme }) => theme.borderRadius};
    border-bottom-right-radius: ${({ theme }) => theme.borderRadius};
    color: ${({ theme }) => theme.colorAccent};
    margin-top: -4px;
  }

  .Dropdown-option {
    color: ${({ theme }) => theme.colorAccent};
    padding: calc(${({ theme }) => theme.gutter} / 2) ${({ theme }) => theme.gutter};
  }

  .Dropdown-option.is-selected,
  .Dropdown-option:hover {
    background-color: ${({ theme }) => theme.backgroundColor};
    color: ${({ theme }) => theme.colorAccent};
  }
`


export const Email = styled.input`
  ${InputCSS}
  grid-column: input / input;

  &:focus {
    border-color: ${({ theme }) => theme.colorAccent};
  }

  &:focus:invalid {
    border-color: ${({ theme }) => theme.colorDanger};
    color: ${({ theme }) => theme.colorDanger};
    &::placeholder {
      color: ${({ theme }) => theme.colorDanger};
    }
  }

  &:focus:valid {
    border-color: ${({ theme }) => theme.colorSuccess};
    color: ${({ theme }) => theme.colorSuccess};
  }

  ${({ valid, theme }) => valid && `
    border-color: ${theme.colorSuccess};
    color: ${theme.colorSuccess};
  `}
`

export const StyledButton = styled(Button)`
  grid-column: button / button;
`

export const Disclaimer = styled.p`
  font-size: ${({ theme }) => theme.fontsizeSmall};
  line-height: ${({ theme }) => theme.lineheightRegular};
  grid-column: input / button;
`
export const Error = styled.p`
  color: ${({ theme }) => theme.colorDanger};
`

export const StatusText = styled.div`
  ${ParagraphStyles}
  grid-column: input / button;
  font-size: ${({ theme }) => theme.heading3FontSize};
`