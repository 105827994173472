import styled from 'styled-components'
import Img from 'gatsby-image'
import { breakpoint } from '../../layout/mixins'

export const Container = styled.figure`
  width: ${({ theme }) => theme.portraitSize};
  height: ${({ theme }) => theme.portraitSize};
  overflow: hidden;
  border-radius: 50%;
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
  border: 2px solid #fff;
  background: #fff;
`

export const Image = styled(Img)``