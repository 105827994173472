import styled from 'styled-components'
import { breakpoint, SectionAlignStyles } from '../../layout/mixins'
import CloseIcon from '../../layout/icons/close.svg'

export const StyledModal = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  z-index: ${({ theme }) => theme.zLevel4};
`

export const Overlay = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background: ${({ theme }) => theme.colorAccentDark};
  z-index: ${({ theme }) => theme.zLevel3};
  opacity: 0.95;

`

export const Content = styled.div`
  position: relative;
  z-index: ${({ theme }) => theme.zLevel4};
  padding: ${({ theme }) => theme.gutterXXLarge};
  border-radius: ${({ theme }) => theme.borderRadius};
  background: ${({ theme }) => theme.backgroundColor};

  ${breakpoint.down('phone')`
    width: 100%;
    height: 100%;
    padding: ${({ theme }) => theme.gutter};
    display: flex;
    justify-content: center;
    align-items: center;
  `}
`

export const Close = styled(CloseIcon)`
  width: 1.11rem;
  height: 1.11rem;
  fill: ${({ theme }) => theme.colorAccentDark};

  ${breakpoint.up('desktop')`
    width: 1rem;
    height: 1rem;
  `}
`

export const StyledButton = styled.button`
  position: absolute;
  top: ${({ theme }) => theme.gutter};
  right: ${({ theme }) => theme.gutter};
  z-index: ${({ theme }) => theme.zLevel4};
  cursor: pointer;
`