import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'gatsby'
import Modal from '../Modal'
import {
  Content, StyledDropdown, ArrowDown, ArrowUp, Email, StyledButton, Disclaimer, Error, StatusText
} from './styles'
import { encodeObject } from '../../utils/helpers'


const SubscribeModal = ({ toggle }) => {
  const emailRef = useRef()
  const formRef = useRef()
  const [submission, setSubmission] = useState({
    email: '',
    office: false
  })
  const [invalid, setInvalid] = useState(true)
  const [status, setStatus] = useState('form')
  const options = [
    'Alla kontor', 'Stockholm', 'Göteborg', 'Linköping', 'Malmö', 'Skövde'
  ]
  const defaultOption = submission.office

  const onChange = (name) => (event) => {
    const { value } = event.target || event
    setSubmission((prevState) => ({
      ...prevState,
      [name]: value
    }))
  }

  const onSubmit = (event) => {
    event.preventDefault()

    const body = encodeObject({
      ...submission,
      'form-name': 'article-notification'
    })

    fetch('/',
      {
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'POST',
        body
      })
      .then((res) => { setStatus('success') })
      .catch((res) => { setStatus('error') })
  }

  useEffect(() => {
    setInvalid(!(formRef.current.checkValidity() && submission.office))
  }, [submission])

  const SubmissionMessage = () => {
    if (status === 'error') {
      return (
        <Content>
          <StatusText>
            <Error>Något gick fel.</Error>
            <p>Vi kunde inte spara ner din e-postadress pga tekniska problem. Prova igen senare.</p>
          </StatusText>
        </Content>
      )
    }
    if (status === 'success') {
      return (
        <Content>
          <StatusText><p>Tack för att du vill ta del av våra artiklar. Du får en notis via mail när nästa artikel publiceras.</p></StatusText>
        </Content>
      )
    }
    return null
  }

  return (
    <Modal
      toggle={toggle}
      data-cy="subscribe-modal"
    >
      <form onSubmit={onSubmit} ref={formRef}>
        <SubmissionMessage />
        { status === 'form' && (
        <Content>
          <StyledDropdown
            options={options}
            value={defaultOption}
            placeholder="Välj kontor"
            arrowClosed={<ArrowDown />}
            arrowOpen={<ArrowUp />}
            onChange={onChange('office')}
          />
          <Email
            ref={emailRef}
            onChange={onChange('email')}
            value={submission.email}
            type="email"
            required
            placeholder="E-post"
            pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
            valid={!invalid}
          />
          <StyledButton disabled={invalid} type="submit" text="Skicka" arrow />
          <Disclaimer>
            Genom att anmäla dig till våra utskick godkänner du våra
            {' '}
            <Link to="/cookies">villkor</Link>
            .
          </Disclaimer>
        </Content>
        )}

      </form>
    </Modal>
  )
}

export default SubscribeModal

SubscribeModal.propTypes = {
  toggle: PropTypes.func.isRequired
}