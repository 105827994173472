import React, { forwardRef } from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'
import { Container, Image } from './styles'

export const query = graphql`
  fragment AuthorImageFragment on DatoCmsFileField {
    fluid(maxWidth: 250, imgixParams: { fm: "jpg", auto: "compress" }, resizes: [90, 180, 250]) {
      ...GatsbyDatoCmsFluid
    }
  }
`

const AuthorImage = forwardRef(({ image, className }, ref) => {
  const sizedImage = {
    ...image.fluid,
    sizes: '180px'
  }

  return (
    <Container ref={ref} data-cy="author-image" className={className}>
      <Image fluid={sizedImage} />
    </Container>
  )
})

export default AuthorImage

export const propTypes = {
  image: PropTypes.shape({
    fluid: PropTypes.shape({
      aspectRatio: PropTypes.number.isRequired,
      base64: PropTypes.string.isRequired,
      sizes: PropTypes.string.isRequired,
      src: PropTypes.string.isRequired,
      srcSet: PropTypes.string.isRequired,
    }).isRequired
  }).isRequired,
  className: PropTypes.string
}

export const defaultProps = {
  className: null
}

AuthorImage.propTypes = propTypes
AuthorImage.defaultProps = defaultProps
