import React from 'react'
import PropTypes from 'prop-types'
import {
  Card, Background, Gradient, Content, Position, Title, Publish, DraftTitle
} from './styles'
import ArticleNumber from '../ArticleNumber'
import siteTheme from '../../layout/theme'
import { Link } from 'gatsby'

const ArticleCard = ({
  id, slug, title, publishWeek, meta, thumbnail
}) => {
  const {
    phone,
    max
  } = siteTheme.breakpoints

  const fluidImage = {
    ...thumbnail?.fluid,
    sizes: `(max-width: ${phone}px) 100vw, 427px`,
  }


  const cardAttributes = meta.status === 'draft' ? {} : {
    as: Link,
    to: `/artikel/${slug}`
  }

  return (
    <Card data-cy={`article-card-${meta.status}`} blur={meta.status === 'draft'} {...cardAttributes}>
      { meta.status === 'draft' ? (
        <>
          <Background fluid={fluidImage}>
            <Gradient />
          </Background>
          <Content>
            <Publish>
              Publiceras v.
              {' '}
              {publishWeek}
            </Publish>
            <DraftTitle>{title}</DraftTitle>
          </Content>
        </>
      ) : (
        <>
          <Background fluid={fluidImage}>
            <Gradient />
          </Background>
          <Content>
            <Position><ArticleNumber id={id} /></Position>
            <Title>{title}</Title>
          </Content>
        </>
      )}
    </Card>
  )
}

export default ArticleCard