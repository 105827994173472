import React from 'react'
import PropTypes from 'prop-types'
import { useStaticQuery, graphql } from 'gatsby'

const articleNumberQuery = graphql`
  query ArticleNumberQuery {
    articles: allDatoCmsArticle(sort: {fields: publishWeek}) {
      edges {
        node {
          id
        }
      }
    }
  }
`

const ArticleNumber = ({ id }) => {
  const { articles } = useStaticQuery(articleNumberQuery)
  const results = articles.edges.findIndex(({ node }) => node.id === id)
  return results !== -1 ? `#${results + 1}` : null
}

export default ArticleNumber

ArticleNumber.propTypes = {
  id: PropTypes.string.isRequired
}