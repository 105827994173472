import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'
import { breakpoint, ParagraphStyles } from '../../layout/mixins'


export const Background = styled(BackgroundImage)`
  width: 100%;
  height: 100%;
  transition: transform ${({ theme }) => theme.transitionRegular}, opacity 0s;
`

export const Card = styled.article`
  width: 100%;
  height: 100%;
  overflow: hidden;
  border-radius: ${({ theme }) => theme.borderRadius};
  background-color: ${({ theme }) => theme.backgroundColorAlt};
  position: relative;


  ${({ blur }) => (blur === true ? `
    ${Background}:after,
    ${Background}:before {
      top: -15px;
      left: -15px;
      right: -15px;
      bottom: -15px;
      transform: scale(1.2);
      filter: blur(4px);
      transition: none !important;
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }
  ` : `
    ${Background} {
      transition: transform 200ms ease-in-out;
    }
    &:hover ${Background} {
      transform: scale(1.05);
      backface-visibility: hidden;
      transform-style: preserve-3d;
    }
  `)}

`

export const Gradient = styled.div`
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;
  background: linear-gradient(180deg, rgba(0, 69, 135, 0) 57.21%, rgba(0, 69, 135, 0.39) 100%);
`
export const Content = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  padding: ${({ theme }) => theme.gutter};
  flex-direction: column;
  justify-content: space-between;
  position: absolute;
  z-index: ${({ theme }) => theme.zLevel1};
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
`

export const Position = styled.strong`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-size: ${({ theme }) => theme.heading3FontSize};
  color: ${({ theme }) => theme.textcolorSecondary};
  font-weight: normal;
`
export const Title = styled.h2`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-size: 1em;
  font-weight: normal;
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const DraftTitle = styled.h2`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-size: 1em;
  font-weight: normal;
  color: ${({ theme }) => theme.textcolorSecondary};
`

export const Publish = styled.strong`
  font-family: ${({ theme }) => theme.fontfamilySecondary};
  font-size: ${({ theme }) => theme.fontsizeSmall};
  font-weight: normal;
  color: ${({ theme }) => theme.textcolorSecondary};
`