import React, { Component } from 'react'
import {
  StyledModal,
  Overlay,
  Content,
  Close,
  StyledButton
} from './styles'
import Portal from '../Portal'
import { Hidden } from '../../layout/mixins'

class Modal extends Component {
  componentDidMount() {
    document.body.classList.add('modal-open')
  }

  componentWillUnmount() {
    document.body.classList.remove('modal-open')
  }

  render() {
    const { children, toggle } = this.props

    return (
      <Portal>
        <StyledModal>
          <Overlay onClick={toggle} />
          <Content>
            <>
              <StyledButton type="button" onClick={toggle}>
                <Hidden>Stäng</Hidden>
                <Close />
              </StyledButton>
              {children}
            </>
          </Content>
        </StyledModal>
      </Portal>
    )
  }
}

export default Modal